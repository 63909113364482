import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./Main";
import SaveTheDate from "./components/SaveTheDate";
import Asistencia from "./components/Asistencia";
import NotFound from "./containers/Err404";
import QrConfirmation from "./components/QrConfirmation";

import Db from "./utils";
// import Importer from "./utils/exporter/invitados";

import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Routes>
      <Route exact path="/" element={<Main />} />
      <Route exact path="/savethedate" element={<SaveTheDate />} />
      <Route exact path="/invitacion/:slug/:invitationId" element={<Asistencia />} />
      <Route exact path="/confirmacionqr/:invitationId/:guessId/:wardId" element={<QrConfirmation />} />
      {/* <Route exact path="/db" element={<Db />} /> */}
      {/* <Route exact path="/exporter" element={<Importer />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
