import React from "react";
// import { useParams } from "react-router-dom";
import BackToTopButton from "./containers/BackToTop";

import Header from "./components/Header";
import Hero from "./components/Hero";
import Introduction from "./components/Introduction";
import BiblicalPhrase from "./components/BiblicalPhrase";
import Parents from "./components/Parents";
import Itinerario from "./components/Itinerario";
import DressCode from "./components/DressCode";
import CountDown from "./components/Countdown";
import Gallery from "./components/Gallery";
import Mesa from "./components/Mesa";
import Login from "./components/Login";
import Footer from "./components/Footer";
import Song from "./containers/Song";

const Main = () => {
  return (
    <React.Fragment>
      <Header />
      <Hero />
      <Introduction />
      <BiblicalPhrase />
      <Parents />
      <Itinerario />
      <DressCode />
      <CountDown />
      <Gallery />
      <Mesa />
      <Login />
      <Footer />
      <Song />
      <BackToTopButton />
    </React.Fragment>
  );
};

export default Main;
