import React from "react";

import images from "../../assets/images";
import "./style.css";

const Mesa = () => {
  const { liverpoolLogo } = images.mesa;

  return (
    <section id="mesa" className="section-with-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <div className="row" data-aos="zoom-in" data-aos-delay="100">
            <div
              className="col-md-6 text-center mesa-container"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div>
                <a
                  href="https://mesaderegalos.liverpool.com.mx/milistaderegalos/50874768"
                  target="{_blank}"
                >
                  <img
                    className="gift-img sink-on-hover"
                    src="https://img.icons8.com/external-line-icons-vinzence-studio/64/000000/external-gift-sale-and-shopping-line-icons-vinzence-studio.png"
                    alt="gift"
                  />
                </a>
              </div>
              <div>
                <br />
                <h3>Mesa de Regalos</h3>
              </div>
              <p>
                <strong className="phrase-gift">
                  ¡El regalo es opcional, la asistencia es Obligatoria!
                </strong>
              </p>
            </div>

            <div
              className="col-md-6 text-center mesa-container"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="2000"
            >
              <div>
                <a
                  href="https://mesaderegalos.liverpool.com.mx/milistaderegalos/50874768"
                  target="{_blank}"
                >
                  <img
                    className="img-fluid sink-on-hover"
                    src={liverpoolLogo}
                    alt="Liverpool"
                  />
                </a>
                <br />
                <br />
                <h3>
                  <strong>Número de Evento:</strong>
                </h3>
                <p>
                  <strong>50874768</strong>
                </p>
                <br />
              </div>
              <div>
                <a
                  className="a-mesabutton shrink-on-hover-button"
                  href="https://mesaderegalos.liverpool.com.mx/milistaderegalos/50874768"
                  target="{_blank}"
                  role="button"
                >
                  <span className=".a-mesabutton">Ir a la Mesa de Regalos</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mesa;
