import React from "react";
import { atcb_init } from "add-to-calendar-button";

import "./atcb.min.css";

const CalendarButton = () => {
  React.useEffect(atcb_init, []);
  return (
    <div className="atcb" style={{ display: "none" }}>
      {JSON.stringify({
        name: "Wedding Event Valeria y Flavio ❤️",
        description:
          "¡ Boda Valeria y Flavio ! Día para divertirme y celebrar con los novios 🤵👰🏻",
        startDate: "2022-10-07",
        endDate: "2022-10-08",
        location: "Guadalajara, jalisco",
        // options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'MicrosoftTeams', 'Yahoo'],
        options: ["Google", "Apple", "Microsoft365", "Outlook.com"],
        trigger: "click",
        timeZone: "America/Mexico_City",
        // iCalFileName: "Reminder-Event",
      })}
    </div>
  );
};

export default CalendarButton;
