import db from "../../utils/firebase.config";
import "./style.css";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import foto from './background.jpg'

const Login = () => {
  const backgroundImage = `url(${foto})`;

  const form = useRef(null);

  const [messageError, setMessageError] = useState("");

  const [validateIsNumber, setValidateIsNumber] = useState(false);

  const [ifBlankBox, setIfBlankBox] = useState(false);

  const navigate = useNavigate();

  const getInvitations = async () => {
    const invitationRef = collection(db, "invitation");
    const invitations = await getDocs(invitationRef);
    if (!invitations.empty) {
      return invitations.docs.map((invitation) => {
        const data = invitation.data();
        const id = invitation.id;
        return { id, ...data };
      });
    }
  };

  const setNumber = (dato) => {
    let valoresAceptados = /^[0-9]+$/;
    if (dato.match(valoresAceptados) && dato.length === 10) {
      setValidateIsNumber(false);
      setMessageError("");
    } else {
      setValidateIsNumber(true);
      setMessageError("Introduzca solo números (10 digitos)");
    }

    if (dato === "") {
      setValidateIsNumber(false);
    }
  };

  const getDataToFirebase = async (invitation, phoneNumber) => {
    const guessRef = collection(db, `invitation/${invitation.id}/guess`);
    const guessQuery = query(guessRef, where("mobile", "==", phoneNumber));
    const querySnapshot = await getDocs(guessQuery);
    if (!querySnapshot.empty) {
      const guessData = querySnapshot.docs[0].data();
      form.current.reset();
      navigate(`/invitacion/${guessData.slug}/${invitation.id}`);
    } else {
      setMessageError("No se encontró número");
      setIfBlankBox(true);
    }
  };

  const findNumber = async (e) => {
    e.preventDefault();
    setIfBlankBox(false);

    const newForm = new FormData(form.current);
    const Data = {
      numberForm: newForm.get("numberForm"),
    };

    const invitations = await getInvitations();
    invitations.map((invitation) =>
      getDataToFirebase(invitation, Data.numberForm)
    );
  };

  return (
    <section id="login" className="section-bg" style={{ backgroundImage }}>
      <div className="container" data-aos="fade-up">
        <div className="text-center">
          <h2 className="section-subtitle login-title">Invitación</h2>
          <h3 className="login-sub-title">
            Por favor ingresa tu número de teléfono
          </h3>
        </div>

        <form onSubmit={findNumber} ref={form}>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10 d-flex">
              <input
                type="text"
                name="numberForm"
                inputMode="numeric"
                maxLength="10"
                minLength="10"
                autoComplete="on"
                // required
                className="form-control-login"
                placeholder="10 Digitos tel"
                onChange={(e) => setNumber(e.target.value)}
              />
            </div>
            {validateIsNumber && (
              <div className="mandatory-field">
                <p>{messageError}</p>
              </div>
            )}
          </div>
          {ifBlankBox && (
            <div className="mandatory-field">
              <p>{messageError}</p>
            </div>
          )}
          <div className="form">
            <div className="php-email-form-login text-center">
              <button type="submit">Entrar</button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
