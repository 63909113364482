import React from "react";

import "./style.css";

const Parents = () => {
  return (
    <div>
      <section id="padrinos" className="section-with-bg">
        <div className="container" data-aos="fade-up">
          <div className="text-center">
            <h2 className="section-subtitle">Con la bendición de</h2>
            {/* <p>Here is our event padrinos</p> */}
          </div>

          <ul
            className="nav nav-tabs"
            role="tablist"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                href="#padres-novia"
                role="tab"
                data-bs-toggle="tab"
              >
                Padres de la Novia
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#padres-novio"
                role="tab"
                data-bs-toggle="tab"
              >
                Padres del Novio
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#padrinos-rings"
                role="tab"
                data-bs-toggle="tab"
              >
                Nuestros Padrinos
              </a>
            </li>
          </ul>

          <h3 className="sub-heading">
          Y que el Señor los haga crecer y aumente el amor entre
           ustedes y hacia los demás, así como también nosotros los amamos a ustedes.
          </h3>
          <p className="sub-heading">1 Tesalonicenses 3:12</p>

          <div
            className="tab-content row justify-content-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {/* <!-- Schdule Day 1 --> */}
            <div
              role="tabpanel"
              className="col-lg-9 tab-pane fade show active"
              id="padres-novia"
            >
              <div className="row padrinos-item">
                <div className="col-md-2">
                  <time></time>
                </div>
                <div className="col-md-10">
                  <div className="speaker">
                    <img
                      src="https://img.icons8.com/external-photo3ideastudio-lineal-photo3ideastudio/64/000000/external-dress-clothes-photo3ideastudio-lineal-photo3ideastudio.png"
                      alt="mama novia"
                    />
                  </div>
                  <h4>Ruth Josefina Gastélum Armenta </h4>
                  <p>
                    Gracias Mamá por apoyarme en todos mis sueños, anhelos y ayudarme a cumplirlos. 
                    Gracias por estar en cada paso, no sería de mí lo que soy sin tu guía. Has sido y siempre serás 
                    esencial en mi vida.
                  </p>
                </div>
              </div>

              <div className="row padrinos-item">
                <div className="col-md-2">
                  <time></time>
                </div>
                <div className="col-md-10">
                  <div className="speaker">
                    <img
                      src="https://img.icons8.com/external-victoruler-solid-victoruler/64/000000/external-wedding-suit-clothes-and-outfit-victoruler-solid-victoruler.png"
                      alt="papá novia"
                    />
                  </div>
                  <h4>Horacio Barceló Ríos</h4>
                  <p>
                    Gracias Papá por la dicha que me diste siempre, por la felicidad y alegrías. Gracias por tu amor, 
                    por todo lo que me enseñaste y cuidaste. Eres parte fundamental de mi vida, el pilar que me impulsa a 
                    ser mejor.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- End Schdule Day 1 --> */}

            {/* <!-- Schdule Day 2 --> */}
            <div
              role="tabpanel"
              className="col-lg-9  tab-pane fade"
              id="padres-novio"
            >
              <div className="row padrinos-item">
                <div className="col-md-2">
                  <time></time>
                </div>
                <div className="col-md-10">
                  <div className="speaker">
                    <img
                      src="https://img.icons8.com/external-photo3ideastudio-lineal-photo3ideastudio/64/000000/external-dress-clothes-photo3ideastudio-lineal-photo3ideastudio.png"
                      alt="mamá novio"
                    />
                  </div>
                  <h4>Clementina García Sanchéz</h4>
                  <p>
                    Mamá gracias por lo que soy, gracias por lo que no soy. Me mostraste amor 
                    incondicionalmente y has creído en mí, incluso cuando ni yo mismo lo hacía.
                  </p>
                </div>
              </div>

              <div className="row padrinos-item">
                <div className="col-md-2">
                  <time></time>
                </div>
                <div className="col-md-10">
                  <div className="speaker">
                    <img
                      src="https://img.icons8.com/external-victoruler-solid-victoruler/64/000000/external-wedding-suit-clothes-and-outfit-victoruler-solid-victoruler.png"
                      alt="papá novio"
                    />
                  </div>
                  <h4>Flavio Calderón Leaños</h4>
                  <p>
                    Gracias papá por estar siempre conmigo, por curar mis heridas de niño, 
                    eres la base de mi vida, la luz de mi camino y la estrella que me guía y me aconseja.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- End Schdule Day 2 --> */}

            {/* <!-- Schdule Day 3 --> */}
            <div
              role="tabpanel"
              className="col-lg-9  tab-pane fade"
              id="padrinos-rings"
            >
              <div className="row padrinos-item">
                <div className="col-md-2">
                  <time></time>
                </div>
                <div className="col-md-10">
                  <div className="speaker">
                    <img
                      src="https://img.icons8.com/external-others-maxicons/62/000000/external-dove-peace-others-maxicons-2.png"
                      alt="padrinos velaion"
                    />
                  </div>
                  <h4>Ana Bertha Hernández Velázquez & Juan Antonio Calderón Leaños</h4>
                  <p>
                    Nos reconforta saber que estarán presentes en nuestras vidas de una manera muy especial. Gracias por el aprecio 
                    y apoyo brindado incondicionalmente, nos complace ser los beneficiarios de su cariño.
                  </p>
                </div>
              </div>

              <div className="row padrinos-item">
                <div className="col-md-2">
                  <time></time>
                </div>
                <div className="col-md-10">
                  <div className="speaker">
                    <img
                      src="https://img.icons8.com/carbon-copy/100/undefined/wedding-rings.png"
                      alt="padrinos anillos"
                    />
                  </div>
                  <h4>Martha Olivia Gastélum Armenta & Manuel Guillermo Silva Hernández</h4>
                  <p>
                    Las palabras no pueden transmitir nuestra gratitud por estar presentes en nuestro matrimonio, 
                    les estamos profundamente agradecidos por ser una guía y apoyo importante en nuestra unión.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- End Schdule Day 2 --> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Parents;
