import React from "react";
// import Countdown from '../../containers/Countdown'

import images from "../../assets/images";
import "./style.css";

const Hero = () => {
  const backgroundImage = `url(${images.hero.hero})`;
  return (
    <section id="hero" style={{ backgroundImage }}>
      <div
        className="hero-container"
        data-aos="zoom-in"
        data-aos-easing="linear"
        data-aos-delay="1000"
      >
        <h1 className="mb-4 pb-0">Valeria & Flavio</h1>
        <p className="mb-4 pb-0">08 Octubre, 2022 - Guadalajara, Jalisco.</p>
        {/* <div><Countdown/></div> */}
      </div>
    </section>
  );
};

export default Hero;
