import React from "react";

import images from "../../assets/images";
import "./style.css";
import foto from "./church.png";

const Itinerario = () => {
  const { coctel, recepcion } = images.itinerario;
  return (
    <section id="schedule">
      <h2 className="h2-itinerario section-subtitle" data-aos="zoom-in">
        Itinerario
      </h2>
      <div className="itinerario">
        <div className="cointainer-itinerario" data-aos="fade-right">
          <img
            className="img-itinerario sink-on-hover"
            // src="https://img.icons8.com/dotty/80/000000/church.png"
            src={foto}
            alt="img"
          />
          <h3>
            <span className="title-itinerario">Ceremonia</span>
          </h3>
          <span className="address-itinerario">
            <p>
              <b>Templo Expiatorio del Santísimo Sacramento</b>
            </p>
            <center>
              C. Manuel López Cotilla #935, 44160 Col Americana, Guadalajara, Jal.
            </center>
          </span>
          <span className="time-itinerario">
            <b>18:00 HRS</b>
          </span>
          <a
            className="a-itinerario shrink-on-hover-button"
            href="https://goo.gl/maps/XLkQznxwmhrfd6Fx8"
            target="{_blank}"
            role="button"
          >
            <span className="map-button ">VER MAPA</span>
          </a>
        </div>

        <div className="cointainer-itinerario" data-aos="zoom-in-down">
          {/* <img className="img-itinerario" src="https://img.icons8.com/external-tulpahn-basic-outline-tulpahn/48/000000/external-wine-hokkaido-tulpahn-basic-outline-tulpahn.png" alt="img"/> */}
          <img
            className="img-itinerario sink-on-hover"
            src={coctel}
            alt="img"
          />
          <h3>
            <span className="title-itinerario">Cóctel</span>
          </h3>
          <span className="address-itinerario">
            <p>
              <b>Hacienda La Aurora</b>
            </p>
            <center>
              Pueblo de San Sebastián #220, 45602 San Pedro Tlaquepaque, Jal.
            </center>
          </span>
          <span className="time-itinerario">
            <b>20:00 HRS</b>
          </span>

          <a
            className="a-itinerario shrink-on-hover-button"
            href="https://g.page/HaciendaLaAurora?share"
            target="{_blank}"
            role="button"
          >
            <span className="map-button">VER MAPA</span>
          </a>
        </div>

        <div className="cointainer-itinerario" data-aos="fade-right">
          {/* <img className="img-itinerario" src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/64/000000/external-party-birthday-flatart-icons-outline-flatarticons.png" alt="img"/> */}
          <img
            className="img-itinerario sink-on-hover"
            src={recepcion}
            alt="img"
          />
          <h3>
            <span className="title-itinerario">Recepción</span>
          </h3>
          <span className="address-itinerario">
            <p>
              <b>Hacienda La Aurora</b>
            </p>
            <center>
              Pueblo de San Sebastián #220, 45602 San Pedro Tlaquepaque, Jal.
            </center>
          </span>
          <span className="time-itinerario">
            <b>21:00 HRS</b>
          </span>

          <a
            className="a-itinerario shrink-on-hover-button"
            href="https://g.page/HaciendaLaAurora?share"
            target="{_blank}"
            role="button"
          >
            <span className="map-button">VER MAPA</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Itinerario;
