import React, { useEffect, useState } from "react";
import images from "../../assets/images";
import foto from "./foto.jpg";
import "./style.css";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import db from "../../utils/firebase.config";

const QrConfirmation = () => {
  const logo = images.logo.logo;
  const backgroundImage = `url(${foto})`;
  const { invitationId, guessId, wardId } = useParams();
  const [guess, setGuess] = useState({});
  const [found, setFound] = useState(false);

  useEffect(() => {
    const getGuess = async () => {
      const guessRef = doc(db, `invitation/${invitationId}/guess/${guessId}`);
      const guess = await getDoc(guessRef);
      if (guess.exists()) {
        const guessData = guess.data();
        if (wardId !== "null") {
          const ward = guessData.wards.find((ward) => ward.id === parseInt(wardId));
          if (ward) {
            setGuess(ward);
            setFound(true);
          } else {
            alert("No se encontró el invitado");
          }
        } else {
          setGuess(guessData);
          setFound(true);
        }
      } else {
        alert("No se encontró la invitación");
      }
    };
    getGuess().then(() => {});
  }, [invitationId, guessId, wardId]);

  return (
    <section id="qr" style={{ backgroundImage }}>
      {found && (
        <div className="qrConfirmation">
          <div>
            <h2>Invitación Confirmada</h2>
            <h3>¡Bienvenido!</h3>
          </div>
        </div>
      )}
      {found && (
        <div className="qrConfirmation-name">
          <div>
            <h3>{guess.name}</h3>
            <h3>Mesa: {guess.table}</h3>
          </div>
        </div>
      )}
      <div className="qr-logo">
        <img src={logo} alt="" />
      </div>
    </section>
  );
};

export default QrConfirmation;
