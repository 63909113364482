import React, { useEffect, useState } from "react";
import BackToHome from "../../containers/BackToHome";
import CalendarButton from "../../containers/CalendarButton";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import db from "../../utils/firebase.config";

import foto from './background.jpg'
import "./style.css";

const Asistencia = () => {
  const backgroundImage = `url(${foto})`;

  
  let { invitationId } = useParams();
  let [familiyAlias, setFamiliyAlias] = useState([]);
  let [guests, setGuests] = useState([]);
  let [showQR, setShowQR] = useState(false);
  let [baseUrl, setBaseUrl] = useState(window.location.origin);

  useEffect(() => {
    const getInvitation = async () => {
      const docRef = doc(db, "invitation", invitationId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setFamiliyAlias(data.familyAlias);
      }
    };

    getInvitation().then(() => {
      getGuestsByInvitationId(invitationId).then((guests) => {
        let guestsArr = [];
        guests.forEach((guest) => {
          const data = guest.data();
          data.id = guest.id;
          data.isWard = false;
          guestsArr.push(data);
          if (data?.wards) {
            data.wards.forEach((ward) => {
              ward.isWard = true;
              ward.guardianId = data.id;
              guestsArr.push(ward);
            });
          }
        });
        setGuests(guestsArr);
      });
    });
  }, [invitationId]);

  /**
   * @param invitationId
   * @returns {Promise<Array<QueryDocumentSnapshot<DocumentData>>|boolean>}
   */
  const getGuestsByInvitationId = async (invitationId) => {
    const guessRef = collection(db, `invitation/${invitationId}/guess`);
    const guessQuery = query(guessRef);
    const querySnapshot = await getDocs(guessQuery);
    if (!querySnapshot.empty) {
      return querySnapshot.docs;
    }
    return false;
  };

  /**
   * @param e
   */
  const updateInvitation = (e) => {
    const newGuests = [...guests];
    newGuests.forEach((guest) => {
      if (guest.name === e.target.value) {
        guest.confirmed = guest.confirmed !== true;
      }
    });
    setGuests(newGuests);
  };

  const confirmAssistance = () => {
    getGuestsByInvitationId(invitationId).then((results) => {
      results.forEach((guest) => {
        const guestData = guest.data();
        const guestId = guest.id;
        const guestFound = guests.find(
          (guest) => guest.name === guestData.name
        );
        if (guestFound) {
          let somethingChanged = false;
          if (guestFound.confirmed !== guestData.confirmed) {
            guestData.confirmed = guestFound.confirmed;
            somethingChanged = true;
          }

          if (guestData?.wards?.length > 0) {
            guestData.wards.forEach((ward) => {
              const wardFound = guests.find(
                (guest) => guest.name === ward.name
              );
              if (wardFound) {
                if (wardFound.confirmed !== ward.confirmed) {
                  const newWards = [...guestData.wards];
                  const wardIndex = newWards.findIndex(
                    (ward) => ward.name === wardFound.name
                  );
                  newWards[wardIndex].confirmed = wardFound.confirmed;
                  guestData.wards = newWards;
                  somethingChanged = true;
                }
              }
            });
          }

          if (somethingChanged) {
            const now = new Date();
            guestData.updatedAt = now.toISOString();
            const guessRef = doc(
              db,
              `invitation/${invitationId}/guess`,
              guestId
            );
            updateDoc(guessRef, guestData).then(() => {
              console.log("guess updated");
            });
          }
          setShowQR(true);
        }
      });
    });
    alert(`Datos de confirmación actualizados`);
  };

  const getQrCode = (invitationId, data) => {
    if (invitationId && data) {
      let url = `${baseUrl}/confirmacionqr/`;
      if (data.isWard) {
        url += `${invitationId}/${data.guardianId}/${data.id}`;
      } else {
        url += `${invitationId}/${data.id}/null`;
      }
      console.log(url);
      return `http://api.qrserver.com/v1/create-qr-code/?data=${url}&size=200x200&bgcolor=ffffff`;
    }
  };

  return (
    <section id="asistencia" className="section-bg" style={{ backgroundImage }}>
      {/* <Header/>
            <Dress/> */}
      <div className="container" data-aos="fade-up">
        <div className="text-center">
          <h2 className="section-subtitle honor-section">
            Tenemos el honor de invitarle
          </h2>
          <h3 className="honor-section-name">{familiyAlias}</h3>
        </div>

        <div className="row contact-info">
          <div className="col-md-4">
            <div className="contact-address">
              <i className="bi bi-geo-alt"></i>
              <h3>1. Verifica tu nombre</h3>
              <address>
                Activa el "Switch" únicamente de las personas que asistirán.
              </address>
            </div>
          </div>

          <div className="col-md-4">
            <div className="contact-phone">
              <i className="bi bi-phone"></i>
              <h3>2. Confirmación</h3>
              <address>Presiona el botón "Confirmar".</address>
              <address>Toma captura de tu QR para ingresar.</address>
            </div>
          </div>

          <div className="col-md-4">
            <div className="contact-email">
              <i className="bi bi-envelope"></i>
              <h3>3. Calendario</h3>
              <address>
                No olvides agregar nuestro evento al calendario de tu
                preferencia.
              </address>
            </div>
          </div>

          <div className="container text-center name-invitation">
            {guests.map((data, id) => (
              <div key={id}>
                <h2>
                  {data.name}
                  <label>
                    <input
                      id={data.id}
                      value={data.name}
                      type="checkbox"
                      onChange={updateInvitation}
                      style={{ display: "none" }}
                      checked={data.confirmed}
                    />
                    <i></i>
                  </label>
                </h2>
              </div>
            ))}
          </div>
        </div>

        <div className="form">
          <div>
            <div className="php-email-form text-center">
              <button onClick={confirmAssistance} type="submit">
                Confirmar Información
              </button>
              <CalendarButton />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container-qr " style={{ marginTop: "15px" }}>
          {showQR &&
            guests.map((data, id) => (
              <React.Fragment key={id}>
                {data.confirmed && (
                  <div className="output-box">
                    <h3>{data.name}</h3>
                    <img
                      src={getQrCode(invitationId, data)}
                      alt="qr-invitation"
                    />
                    <h3><strong>Mesa </strong># {data.table}</h3>
                    <a
                      className="qr-code"
                      href={getQrCode(invitationId, data)}
                      download="QRCode"
                    >
                      <button className="button-qr" type="button">
                        Descargar
                      </button>
                    </a>
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>
      </div>

      <div className="help">
        <div className="help-text">
            ¿Dudas o problemas?
          <a href="https://wa.me/523311962551/?text=%C2%A1Hola!%20tengo%20un%20problema" target="{_blank}"> 
            <span className="help-url">AYUDA</span> 
          </a>
          <BackToHome/>
        </div>
      </div>
    </section>
  );
};

export default Asistencia;

