import React from "react";

import Song from "../../containers/Song";
import Countdown from "../../containers/Countdown";
import BackToHome from "../../containers/BackToHome";

import "./style.css";
const SaveTheDate = () => {
  return (
    <section>
      <video autoPlay loop muted playsInline>
        <source
          src="https://res.cloudinary.com/gma-systems/video/upload/v1653240732/Wedding/SaveThedateSMSize_tnsnzz.mp4"
          type="video/mp4"
        />
      </video>
      <div className="text-video">
        <h1>Save the Date</h1>
        <h3>08 | 10 | 2022</h3>
        <br></br>
        <h2>Valeria & Flavio</h2>
        <br></br>
        <h6>We will become a family in</h6>
        <div className="text-center" data-aos="zoom-in">
          <Countdown />
        </div>
      </div>
      <Song />
      <BackToHome/>
    </section>
  );
};

export default SaveTheDate;
