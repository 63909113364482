import React from "react";
import { useEffect, useState } from "react";

import "./style.css";

const BackToTopButton = () => {
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    const handleEvent = () => {
      if (window.scrollY > 220) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    };
    window.addEventListener("scroll", handleEvent);

    return () => {
      window.removeEventListener("scroll", handleEvent);
    };
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    // <div>
    //     {backToTopButton && (
    //         <button style={{
    //             position: "fixed",
    //             bottom: "50px",
    //             right: '50px',
    //             height: '50px',
    //             width: '50px',
    //             fontSize: '50px'
    //         }} onClick={scrollUp}>+</button>
    //     )}
    // </div>

    <div>
      {backToTopButton && (
        <button
          className="button-icon-BtoTop shrink-on-hover-button"
          onClick={scrollUp}
        >
          <img
            className="scroll-icon shrink-on-hover-button"
            src="https://img.icons8.com/ios/50/000000/love-arrow--v2.png"
            alt="arrow"
          />
        </button>
      )}
    </div>
  );
};

export default BackToTopButton;
