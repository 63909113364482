import React from "react";

import Typewriter from "typewriter-effect";
import images from "../../assets/images";
import "./style.css";

const Introduction = () => {
  const { foto1, foto2 } = images.introduction;
  return (
    <div
      id="couple"
      className="bride-groom clear section-padding bg-cream"
      data-scroll-index="1"
    >
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div
            className="col-md-12 text-center mb-30 animate-box"
            data-animate-effect="fadeInUp"
          >
            <h6 className="section-subtitle">We're getting married!</h6>
          </div>
          <div
            className="col-md-4 offset-md-2 text-center animate-box"
            data-animate-effect="fadeInLeft"
          >
            <div className="item mb-30">
              <div className="img">
                <img src={foto1} alt="" />
              </div>
              <div className="info valign">
                <div className="full-width">
                  <h6>Flavio Calderón</h6> <span>The Groom</span>

                  {/* <p>"Tú eres la respuesta a todas mis plegarias. Eres una canción, un sueño, 
                  un susurro, Te amo ahora mientras escribo esto, y te amo ahora mientras lees esto"</p>  */}

                </div>
              </div>
              {/* seccion de maquina de escribir */}
              <Typewriter
              options={{
                strings: ['Valeria', 'Mi amor...'],
                autoStart: true,
                loop: true,
              }}
                onInit={(typewriter)=> {
                typewriter
                .typeString(`"Tú eres la respuesta a todas mis plegarias, eres una canción, un sueño, un susurro, te amo ahora mientras escribo esto y te amo ahora mientras lees esto"`)
                .pauseFor(1500)
                .deleteAll()
                .start();
                }}
                />
               
            </div>
          </div>
          <div
            className="col-md-4 text-center animate-box"
            data-animate-effect="fadeInRight"
          >
            <div className="item mb-30">
              <div className="img">
                <img src={foto2} alt="" />
              </div>
              <div className="info valign">
                <div className="full-width">
                  <h6>Valeria Barceló</h6> <span>The Bride</span>
                  {/* <p>
                    "Estoy tan orgullosa de ti mi amor. Y estoy orgullosa de
                    poder llamarte mi marido"
                  </p> */}
                </div>
              </div>
              {/* seccion de maquina de escribir */}
              <Typewriter
              options={{
                strings: ['Flavio', 'Mi tesoro...'],
                autoStart: true,
                loop: true,
              }}
                onInit={(typewriter)=> {
                typewriter
                .typeString(`"Me has dado la felicidad que no había tenido jamás, tu amor es invaluable para mi vida. Nuestras almas se unieron y se reconocieron, tú oraste, yo oré y Dios nos unió. Te amo"`)
                .pauseFor(1500)
                .deleteAll()
                .start();
                }}
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
